* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background: #ffffff;
}

.slider {
  align-items: stretch;
  margin:0 10px;
  overflow: hidden;
  padding: 2rem 0;
  display: flex;
  height: 100% !important;
}

/* .slider img > img{
  width: 210px;
  height: 120px;
} */

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  position: relative;
}

.custom-dot-list-style button{
  border: none;
  background: rgb(3, 109, 84);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: rgb(71, 204, 216) !important;
}

.react-multiple-carousel__arrow--right{
  right: calc(0% + 1px)
}

.react-multiple-carousel__arrow--left{
  left: calc(0% + 1px)
}