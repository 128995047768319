.board {
  display: flex;
  flex-wrap: wrap;
  width: 275px; /* Adjust the size of the board */
  border: 3px solid #38B2AC;/* Border for the whole board */
}

.row {
  display: flex;
  /* width: 100%; */
}

.cell {
  width: 30px; /* Adjust the size of the cells */
  height: 30px;
  border: 1px solid #ddd; /* Border for each cell */
  text-align: center;
  font-size: 20px; /* Adjust font size as needed */
  line-height: 30px;
}

select {
  padding: 5px 10px;
  margin-right: 10px;
  border-color: #38B2AC;
  border: 2px;
}

button {
  padding: 5px 15px;
  margin: 5px;
  background-color: #38B2AC; /* Your specified color */
  color: white;
  border: none;
  cursor: pointer;
}

/* Highlight the borders to visually separate the 3x3 grids */
.row:nth-child(3n) .cell {
  border-bottom: 2px solid #38B2AC;
}

.cell:nth-child(3n) {
  border-right: 2px solid #38B2AC;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}